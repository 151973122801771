//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gsap from 'gsap'
import ScrollTrigger from '../../node_modules/gsap/dist/ScrollTrigger'

import ResponsiveImageStrapi from '@/components/modules/ResponsiveImageStrapi'

export default {
	props: {
		data: {
			type: Object,
			default: null,
		},
	},
	components: {
		ResponsiveImageStrapi,
	},
	mounted() {
		this.$nextTick(this.Parallax)
	},
	methods: {
		Parallax() {
			setTimeout(() => {
				const factor = 8;
				gsap.registerPlugin(ScrollTrigger)
				const page = this.$refs.section.closest('.page')
				const bg = this.$refs.bg.querySelector('img')
				const movement = -(bg.offsetHeight / factor)
				const tl = gsap.timeline({
					scrollTrigger: {
						scroller: page,
						trigger: this.$refs.section,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						// end: "+=500",
						// markers: true
					},
				})
				tl.to(bg, {
					y: movement,
				})
			}, 0)
		},
	},
}

